.page-title {
  text-align: center;
  font-size: 2rem; /* Adjust the size as needed */
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #333; /* Or any color that fits your design */
  font-family: 'Arial', sans-serif; /* Choose a font that fits your design */
}

.app-container {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.candidate-card {
  flex-basis: calc(100% - 1rem); /* Full width for small screens */
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

/* Stretch from 600px to 900px */
@media (min-width: 600px) and (max-width: 900px) {
  .candidate-card {
    flex-basis: calc(50% - 1rem); /* 2 cards per row */
  }
}

/* Beyond 900px, stop stretching and center */
@media (min-width: 900px) {
  .app-container {
    max-width: 900px; /* Maximum container width */
    margin-left: auto;
    margin-right: auto;
  }
  .candidate-card {
    flex-basis: calc(50% - 1rem); /* Maintain 2 cards per row */
  }
}

.candidate-image {
  width: 50%;
  height: auto;
  object-fit: cover;
  float: left;
  padding: 0.75rem;
  padding-bottom: 2px;
}

.candidate-content {
  padding: 0.75rem;
}

.candidate-title {
  margin-top: 0;
}

.donate-button {
  background-color: #4CAF50;
  color: white;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px auto 20px;
  display: block;
}

.donate-button:hover {
  background-color: #45a049;
}

.donation-area {
  display: flex;
  align-items: center;
  width: 100%;
}

.progress-bar-container {
  flex-grow: 1;
  background-color: #eee;
  border-radius: 5px;
  margin-left: 10px;
  height: 20px;
  overflow: hidden;
  position: relative; /* Ensure this is here */
}

.progress-bar {
  background-color: #81c784 !important;
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out; /* Smooth transition for the bar */
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 20px; /* Align vertically */
  color: black; /* Ensure text is visible */
  font-size: 0.75rem; /* Adjust font size as needed */
  top: 0; /* Align to the top of the container */
  left: 0; /* Align to the left of the container */
}

/* Clearfix for the floated image */
.candidate-card::after {
  content: "";
  display: table;
  clear: both;
}

